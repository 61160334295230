<template>
  <div class="app-container">
    <div class="dateSet">
      <div class="dateSetWrapper">
        <color-text-btn @click="dateSet" v-if="checkPermission('externaladmin:financialManagement:dateSet')">结账日期设置</color-text-btn>
        <dateSetDialog :visible.sync="visible"></dateSetDialog>
      </div>
    </div>
    <search-header :query="query" @search="search" :timeSelect="timeSelect" />
    <CommonTable
      :selection="false"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      @sort-change="sortChange"
    >
      <template #levelName="{ scoped: row }"> {{ get(row, 'paymentAccountSimple.memberLevel.levelName') }} </template>
      <template #totalCreditAmount="{ scoped: row }"> {{ get(row, 'paymentAccountSimple.totalCreditAmount') }} </template>
      <template #updateTimeSlot="{ scoped: row }"> {{ parseTime(get(row, `paymentAccountSimple.totalCreditAmountUpdateTime`)) }} </template>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn @click="hanldeBalance(row)" v-if="checkPermission('externaladmin:financialManagement:creditModify')">额度增减</color-text-btn>
          <color-text-btn uiid="zd-monthBills" @click="handleHistory(row)" v-if="checkPermission('externaladmin:financialManagement:monthBills')">月结账单</color-text-btn>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import dateSetDialog from './module/dateSetDialog.vue'
import searchHeader from './module/searchHeader.vue'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { balanceCols as cols } from './module/cols'
import { get } from 'lodash'

export default {
  name: 'userBalance',
  components: { searchHeader, dateSetDialog },
  mixins: [initDataMixin],
  data() {
    return {
      visible: false,
      cols,
      sup_this: this,
      url: '/externaladmin/authService/user/userCreditList',
      query: {
        memberLevelId: '',
        nickName: ''
      },
      timeSelect: {
        time: null
      }
    }
  },
  methods: {
    search() {
      if (typeof this.timeSelect.time == 'object') {
        if (this.timeSelect.time) {
          this.query.totalCreditAmountUpdateStartTime = this.timeSelect.time[0] + ' 00:00:00'
          this.query.totalCreditAmountUpdateEndTime = this.timeSelect.time[1] + ' 23:59:59'
        } else {
          this.query.totalCreditAmountUpdateStartTime = ''
          this.query.totalCreditAmountUpdateEndTime = ''
        }
      }
      this.searchChange()
    },
    // 结账日期设置
    dateSet() {
      this.visible = true
    },
    parseTime,
    hanldeBalance(data) {
      this.$router.push({
        path: '/financialManagement/creditAmountChange',
        query: { id: data.id }
      })
    },
    updateForm(data) {
      const { balanceChange, modifiedBalance } = data
      this.form.balanceChange = balanceChange
      this.form.modifiedBalance = modifiedBalance
    },
    sortChange({ prop, order }) {
      if (prop == 'levelName') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'member_level',
              asc: state
            }
          ]
        }
        this.init()
      } else if ((prop = 'updateTime')) {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'update_time',
              asc: state
            }
          ]
        }
        this.init()
      }
    },
    handleHistory(data) {
      const { id } = data
      this.$router.push({ path: '/financialManagement/userCreditBill', query: { id } })
    },

    get
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .dateSet {
    position: relative;
    height: 14px;
    .dateSetWrapper {
      position: absolute;
      right: 0;
      width: 80px;
    }
  }
}
</style>

export const balanceCols = [
  {
    prop: 'nickName',
    label: '分销商名称',
    minWidth: '200'
  },
  {
    prop: 'levelName',
    label: '会员等级',
    minWidth: '200',
    slotName: 'levelName'
  },
  {
    prop: 'totalCreditAmount',
    label: '账户信用额度',
    minWidth: '200',
    slotName: 'totalCreditAmount'
  },
  {
    prop: 'totalCreditAmountUpdateTime',
    label: '更新时间',
    minWidth: '200',
    slotName: 'updateTimeSlot',
    sortable: true
  }
]

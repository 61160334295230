<template>
  <div class="search-warpper formPart">
    <el-form :inline="true" :model="query" class="demo-form-inline" uiid="zd-search">
      <el-form-item label="用户：">
        <el-input
          @change="$emit('search')"
          uiid="zd-nickName"
          size="mini"
          style="width: 200px"
          clearable
          placeholder="请输入用户名称"
          v-model="query.nickName"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户等级：">
        <el-select
          size="mini"
          @change="$emit('search')"
          style="width: 200px"
          v-model="query.memberLevelId"
          clearable
          placeholder="请选择用户等级"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="更新时间：">
        <el-date-picker
          v-model="timeSelect.time"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd"
          placeholder="请选择时间"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 300px"
          @change="$emit('search')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          uiid="zd-submit"
          style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
          type="primary"
          @click="$emit('search')"
          icon="el-icon-search"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getVipList } from '@/api/vipLevel'
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    timeSelect:{
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      options: []
    }
  },
  async created() {
    //请求调用会员等级接口，将detail赋值给options
    try {
      const page = { pageIndex: 1, pageSize: 0 }
      const { code, detail } = await getVipList({ page })
      if (code == 0) {
        this.options = detail.map((item) => ({
          label: item.levelName,
          value: item.id
        }))
      }
    } catch (error) {}
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>

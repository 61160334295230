<template>
  <el-dialog
    title="结账日期设置"
    :visible.sync="visible"
    width="600px"
    class="dialog-warpper"
    :before-close="handleClose"
  >
    <div v-loading="openLoading">
      <el-form label-width="130px" style="margin-bottom: 20px" :model="form">
        <el-form-item label="每月生成账单日期:">
          <!-- <el-date-picker
            v-model="form.billDate"
            size="small"
            value-format="dd"
            placeholder="请选择时间"
            style="width: 300px"
          >
          </el-date-picker> -->

          <el-select
            v-model="form.billDate"
            size="small"
            placeholder="请选择生成账单日期"
            style="width: 300px"
          >
            <el-option v-for="item in 28" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="每月最晚还款日期:">
          <!-- <el-date-picker
            v-model="form.repaymentDate"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="请选择时间"
            style="width: 300px"
          >
          </el-date-picker> -->
          <el-select
            v-model="form.repaymentDate"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="请选择还款日期"
            style="width: 300px"
          >
            <el-option v-for="item in 28" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :loading="loading" type="primary" @click="onConfirm">确定</el-button>
        <el-button size="mini" @click="onBack">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { updateSettlementDay,getLastUpdateDay } from '@/api/financial/userCredit'
import { parseTime } from '@/utils'
export default {
  data() {
    return {
      openLoading:false,
      loading: false,
      form: {
        billDate: '',
        repaymentDate: ''
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  watch:{
    visible:{
      async handler(n){
      if(n){
        try {
          this.openLoading = true
           const {detail} =  await getLastUpdateDay()
           this.form.billDate = detail.billDate
           this.form.repaymentDate = detail.repaymentDate
        } catch (error) {
          this.$message.error('获取结账日期失败')
        }finally{
          this.openLoading = false
        }
        
      }
      },
      immediate:true
    }
  },
  methods: {
    parseTime,
    handleClose() {
      this.$emit('update:visible', false)
    },
    onBack() {
      this.handleClose()
    },
    async onConfirm() {
      try {
        this.loading = true
        const { billDate, repaymentDate } = this.form
        const { code } = await updateSettlementDay({
          billDate,
          repaymentDate
        })
        if ($SUC({ code })) {
          this.$message.success('结账日期设置成功')
          this.handleClose()
        } else {
          this.$message.error('结账日期设置失败')
        }
      } catch (e) {
        this.$message.error('结账日期设置失败')
      } finally {
        this.loading = false
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  font-size: 14px;
  .title {
    color: #595961;
    margin-bottom: 30px;
    font-weight: 500;
    background-color: #f7f8fa;
    height: 32px;
    line-height: 32px;
  }
  .flex-warpper {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    .flex-item {
      flex: 1;
      width: 33.3%;
      min-width: 33.3%; // 加入这两个后每个item的宽度就生效了
      max-width: 33.3%;
      color: #969799;
      margin-bottom: 20px;
      .normal {
        color: #2d2d2d;
      }
    }
  }
  ::v-deep {
    .el-form-item__label {
      color: #969799;
      font-weight: 400;
    }
    .el-radio__label {
      color: #2d2d2d;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
